import type { AvailableLanguages } from '#/lang';

import { AvailableLanguagesConst } from '#/lang';
import { LocalStorageKeys, typedLocalStorage } from '#/utils';
import { ClerkProvider } from '@clerk/clerk-react';
import {
  daDK,
  deDE,
  enUS,
  esES,
  frFR,
  itIT,
  nlBE,
  nlNL,
  plPL,
  ptPT,
  svSE,
} from '@clerk/localizations';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import '~/yet-another-react-lightbox/dist/styles.css';

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

function checkLang(l: string): l is AvailableLanguages {
  return (AvailableLanguagesConst as readonly string[]).includes(l);
}

const lang =
  checkLang(
    navigator.language === 'nl-BE' ? 'rbe' : navigator.language.split('-')[0]
  ) &&
  ((navigator.language === 'nl-BE'
    ? 'rbe'
    : navigator.language.split('-')[0]) as AvailableLanguages | null);

const localLang = typedLocalStorage.get(
  LocalStorageKeys.lang
) as AvailableLanguages | null;

const initLang: AvailableLanguages = localLang || lang || 'en';

const getLocal = () => {
  switch (initLang) {
    case 'en': {
      return enUS;
    }
    case 'fr': {
      return frFR;
    }
    case 'es': {
      return esES;
    }
    case 'de': {
      return deDE;
    }
    case 'da': {
      return daDK;
    }
    case 'it': {
      return itIT;
    }
    case 'rbe': {
      return nlBE;
    }
    case 'nl': {
      return nlNL;
    }
    case 'fi': {
      return enUS;
    }
    case 'pl': {
      return plPL;
    }
    case 'pt': {
      return ptPT;
    }
    case 'sv': {
      return svSE;
    }
    default: {
      return enUS;
    }
  }
};

interface Props {
  children: React.ReactNode;
}

const ClerkWithRouting = ({ children }: Props) => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      localization={getLocal()}
      publishableKey={PUBLISHABLE_KEY}
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
    >
      {children}
    </ClerkProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ClerkWithRouting>
        <App />
      </ClerkWithRouting>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
